import React from 'react';
import Link from 'gatsby-link';
import Helmet from 'react-helmet';
import Layout from '../components/layout'
export const pageQuery = graphql`
  query BlogQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            path
            type
            tags
            date
          }
        }
      }
    }
  }
`;

const BlogPage = ({ data }) => {
  let postFilterType = 'all';
  return (
    <Layout>
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Blog | ${data.site.siteMetadata.title}`}</title>
          <link rel="canonical" href="/blog" />
        </Helmet>
        <section className="section">
          {/* <div className="tabs is-centered">
          <ul>
            <li className="is-active">
              <a onClick={() => postFilterType = 'Ionic'}>Ionic</a>
            </li>
            <li>
              <a onClick={() => postFilterType = 'Angular'}>Angular</a>
            </li>
          </ul>
        </div> */}
          {data.allMarkdownRemark.edges
            .filter(post => {
              if (postFilterType === 'all') {
                return true;
              } else {
                return post.node.frontmatter.tags.includes(postFilterType);
              }
            })
            .map(post => (
              <article className="media" key={post.node.id}>
                <figure className="media-left">
                  <p className="image is-64x64 hero is-bold is-primary" />
                </figure>
                <div className="media-content">
                  <div className="content">
                    <Link className="title" to={post.node.frontmatter.path}>
                      {post.node.frontmatter.title}
                    </Link>
                    <p dangerouslySetInnerHTML={{ __html: post.node.excerpt }} />
                  </div>
                </div>
              </article>
            ))}
        </section>
      </div>
    </Layout>
  );
};

export default BlogPage;
